<template>
  <div :class="$style.root">
    <CoreSvgBackgroundItemComponent
      v-for="(svgIndex, index) in svgIndexes"
      :key="`${svgIndex}-${index}`"
      :svgIndex="svgIndex"
      :slideType="slideTypes?.length ? slideTypes[index] : null"
      :data-color-scheme="routeName"
    />
  </div>
</template>

<script setup>
import { range, sampleSize } from 'lodash-es'

const route = useRoute()
const nuxtApp = useNuxtApp()


const svgCount = ref(16)
const svgIndexes = ref([])

const routeName = ref(document.documentElement.getAttribute('data-route-name'))
const slideTypes = ref(document.documentElement.getAttribute('data-slide-types')?.split(','))

const initSelf = () => {
  setTimeout(() => {
    routeName.value = document.documentElement.getAttribute('data-route-name')
    slideTypes.value = document.documentElement.getAttribute('data-slide-types')?.split(',')

    const options = range(1, svgCount.value)
    const amount = route?.name?.includes('index') ? slideTypes?.value?.length : 1
    svgIndexes.value = sampleSize(options, amount)
  }, 350)
}

const maxHeight = ref(undefined)
const setHeight = () => {
  const bodyHeight = document?.body?.getBoundingClientRect()?.height
  maxHeight.value = bodyHeight
}

const fromName = ref(null)
nuxtApp.hook('page:finish', () => {
  const toName = nuxtApp.$getRouteBaseName()
  if(nuxtApp.$getRouteBaseName() !== 'organisation' && toName !== fromName.value) {
    initSelf()
    fromName.value = toName
  }
})
</script>


<style module>
.root {
  width: 100vw;

  height: var(--layout-height, 0px);
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: -1;

  pointer-events: none;
}
</style>
