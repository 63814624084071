<template>
  <div :class="$style.root">
    <component
      :is="dynamicComponentName"
      :class="$style.svg"
      :data-type="slideType"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    svgIndex: {
      type: Number,
      required: true,
    },
    slideType: {
      type: String,
      required: false,
    },
  })

  const dynamicComponentName = computed(() => {
    return `SvgoGg-bg-${props.svgIndex}`
  })
</script>


<style module>
.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: block;
}

/* 1 */
.svg {
  fill: transparent;
}

/* 2 */
.svg[data-type='bulletinBoardEntry'] * {
    fill: var(--color--bulletin-board);
}

.svg[data-type='calendarEntry'] * {
    fill: var(--color--calendar);
}

.svg[data-type='projectEntry'] * {
    fill: var(--color--projects);
}

/* 2 */
.root[data-color-scheme='bulletin-board'] * {
    fill: var(--color--bulletin-board);
}

.root[data-color-scheme='calendar'] * {
    fill: var(--color--calendar);
}

.root[data-color-scheme='community'] * {
    fill: var(--color--community);
}

.root[data-color-scheme*='organisation'] * {
    fill: var(--color--organisation);
}

.root[data-color-scheme='projects'] * {
    fill: var(--color--projects);
}

</style>
